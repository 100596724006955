import { createContext, type FC, useCallback, useContext } from 'react'
import { useLocalStorage } from '@lib/hooks'
import { Country, Currency, getUserTimeZone, isWidget } from '@lib/services'
import { type CharityCampaign } from '../service/charity'
import { CampaignSetup } from '../campaignSetup'

export enum Tip {
  FivePercent = 'five-percent',
  TenPercent = 'ten-percent',
  FifteenPercent = 'fifteen-percent',
  Other = 'other'
}

export const tipValues: Record<string, number | undefined> = {
  [Tip.FivePercent]: 5,
  [Tip.TenPercent]: 10,
  [Tip.FifteenPercent]: 15,
  [Tip.Other]: undefined
}

export interface DonorDetails {
  addressLine1: string
  houseNumber: string
  country: string
  email: string
  firstName: string
  lastName: string
  isAddressFromLookup: boolean
  postalCode: string
}

export interface PaymentDetailsData {
  giftAid: boolean
  payFees: boolean
  taxReceipt: boolean
  tipPercent: Tip
  tipValue: number
  tipFees: boolean
}
export interface NightType {
  key: string
  value: number
}

export interface Plan {
  campaign: string
  causes: Record<string, number>
  chainCode: string
  charity: CharityCampaign
  chainCodeCharity: CharityCampaign
  detectedTimezone: string
  firstNight: number
  totalNight: NightType
  multiplier: Multiplier
  night30: boolean
  split: SplitType
  timezone: string
  donorDetails: DonorDetails
  paymentDetails: PaymentDetailsData
  startDateOffset: number
  autoEnroll: string
  fridayGiving: number
  fridayGivingOptIn: string
  emailOptIn: boolean
  charityEmailOptIn: boolean
  wakf: number
  wakfOptIn: string
}

export enum SplitType {
  Equally = 'equally',
  MoreOdd = 'more-odd',
  More27 = 'more-27',
  MoreArafah = 'more-arafah',
  NotSelected = 'not-selected',
  MoreLastTenOddNights = 'more-10-odd-nights'
}
export enum NightTypeOpt {
  AllAvailable = 'allAvailable',
  LastTenAvailable = 'lastTenAvailable'
}

export const mtnSplitTexts: Record<string, string> = {
  [SplitType.Equally]: 'Split equally',
  [SplitType.MoreOdd]: 'More on odd nights',
  [SplitType.More27]: 'More on 27th',
  [SplitType.MoreLastTenOddNights]: 'More on last 10 odd nights'
}

export const mtdSplitTexts: Record<string, string> = {
  [SplitType.Equally]: 'Equally on all days',
  [SplitType.MoreArafah]: 'More on day of Arafah'
}

export enum Multiplier {
  OnePointFive = 1.5,
  Double = 2,
  Triple = 3
}

export const defaultCharity: CharityCampaign = {
  about: '',
  registration_number: '',
  campaign: '',
  causes: [],
  charity_id: '',
  charity_name: '',
  country: Country.GB,
  currency: Currency.GBP,
  gift_aid: false,
  privacy_policy_url: '',
  tax_receipt: false,
  partner: ''
}

export enum CampaignDates {
  FirstNight = 20,
  StartDateOffset = 0
}

export const getDefaultPlan = (): Plan => {
  const tz = getUserTimeZone()
  return {
    campaign: CampaignSetup.currentCampaign,
    causes: {},
    chainCode: '',
    chainCodeCharity: defaultCharity,
    charity: defaultCharity,
    detectedTimezone: tz,
    firstNight: 0,
    multiplier: Multiplier.Double,
    night30: true,
    split: SplitType.NotSelected,
    totalNight: { key: '', value: 0 },
    fridayGiving: 0,
    fridayGivingOptIn: '',
    wakf: 0,
    wakfOptIn: '',
    emailOptIn: true,
    charityEmailOptIn: false,
    timezone: tz !== '' ? tz : 'Europe/London',
    donorDetails: {
      addressLine1: '',
      country: '',
      email: '',
      firstName: '',
      lastName: '',
      houseNumber: '',
      postalCode: '',
      isAddressFromLookup: false
    },
    paymentDetails: {
      giftAid: false,
      taxReceipt: false,
      payFees: true,
      tipPercent: Tip.TenPercent,
      tipValue: 0,
      tipFees: true
    },
    startDateOffset: CampaignDates.StartDateOffset,
    autoEnroll: ''
  }
}

const checkoutPlanContext = createContext({
  plan: getDefaultPlan(),
  setPlan: (p: Plan) => console.log(p),
  resetPlan: () => { },
  setCharity: (c: CharityCampaign) => console.log(c)
})

export const useCheckoutPlan = (): usePlan => {
  const result = useContext(checkoutPlanContext)
  if (result.plan.campaign !== CampaignSetup.currentCampaign) {
    result.resetPlan()
  }
  return result
}
interface usePlan {
  plan: Plan
  setPlan: (p: Plan) => void
  resetPlan: () => void
  setCharity: (c: CharityCampaign) => void
}

export const CheckoutPlanProvider: FC = ({ children }) => {
  const [plan, setPlan] = useLocalStorage<Plan>('checkout_plan', getDefaultPlan())

  const setCharity = (charity: CharityCampaign): void => {
    const causes = plan.charity.charity_id === charity.charity_id ? plan.causes : {}
    setPlan({ ...plan, charity, causes })
  }
  const resetPlan = useCallback((): void => {
    if (isWidget) {
      setPlan({ ...getDefaultPlan(), charity: plan.charity })
      return
    }
    setPlan(getDefaultPlan())
  }, [setPlan, plan.charity])
  const value = {
    plan,
    setPlan,
    resetPlan,
    setCharity
  }
  return <checkoutPlanContext.Provider value={value}>{children}</checkoutPlanContext.Provider>
}
