import { BrowserRouter as Router, redirect, Route, Routes } from 'react-router-dom'
import { type FC } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { AuthenticatedRoute, ErrorBoundary, PageViewEvents, ScrollToTop, Spinner } from '@lib/components'
import { ProvideAuth } from '@lib/hooks'
import { FlowRedirect, ShareRedirect } from '~/components/CheckoutRedirects'
import { AddressLookupStore, CheckoutPlanProvider, defaultVariants, PaymentContainer, MTNtracking, isLive, TestVersion, ActiveExperiments } from '@shamaazi/mytennights'
import { Experiment, experimentDebugger, Variant } from '@lib/react-ab-test'
import {
  About,
  Causes,
  CharityLanding,
  Contact,
  CookiePreferences,
  Details,
  ErrorPage,
  FAQ,
  MarketingPreferences,
  NotFound,
  PrivacyPolicy,
  ScheduleNight,
  SelectCharity,
  TermsAndConditions
} from '~/pages'
import Share from './pages/Share'
import ShareOptions from './pages/ShareOptions'
import { CookieBanner, Login, Navigation } from './components'
import { browserExceptions, SplittestingStore } from '@lib/services'

import { configure } from 'mobx'
import { observer } from 'mobx-react'
import { Home } from '~/pages/home'
import { Payment } from '~/pages/payment'
import { Pages } from '~/components'
import { ScheduleList } from '~/pages/profile/ScheduleList'
import { Schedule } from '~/pages/profile/Schedule'
import { FGScheduleList } from './pages/profile/FGScheduleList'
import { FGScheduleDetail } from './pages/profile/FGScheduleDetail'
import { Fees } from './pages/Fees'

if (!isLive) {
  experimentDebugger.enable()
}

configure({
  enforceActions: 'never'
})

void browserExceptions.initialize()
MTNtracking.initialize()

const queryClient = new QueryClient()

interface AppProps {
  readonly splittestingStore: SplittestingStore
}

const addressLookupStore = new AddressLookupStore()

const splitTestingStore = new SplittestingStore(defaultVariants)
void splitTestingStore.load()

const App: FC<AppProps> = observer(({ splittestingStore }) => {
  return <>
    {splittestingStore.isLoading &&
      <div className="flex justify-center mt-20"><Spinner className="text-mtn-blue" /></div>}
    {!splittestingStore.isLoading && !splittestingStore.error &&
      <ErrorBoundary component={<ErrorPage />}>
        <ProvideAuth campaignQueryParameter={''}>
          <QueryClientProvider client={queryClient}>
            <CheckoutPlanProvider>
              <Router>
                <PageViewEvents tracking={MTNtracking} trackable={MTNtracking.isTrackableUrl} />
                <ScrollToTop />
                <Routes>
                  <Route path={Navigation.home} element={<Home nextPage={Navigation.charitySelection()} />} />
                  <Route path={Navigation.partnerLanding()} element={<Home nextPage={Navigation.charitySelection()} />} />

                  {/* Allow partner prefix in the route to allow opening MTN site outside in-app browser which can redirect to partner landing page rather than the MTN homepage */}
                  {[Navigation.charitySelection(), Navigation.partnerCharitySelection()].map(route => {
                    return <Route path={route} element={<FlowRedirect page={Pages.Charity}>
                      {/* The below experiment is added here so that the experiment is passed in the mixpanel event on page load. Exact logic handled in the HomePageCharities component */}
                      <Experiment name={ActiveExperiments.CharityPennyAppealUK}>
                        <Variant name={TestVersion.control}>
                          <SelectCharity nextPage={Navigation.charityCauses()} />
                        </Variant>
                        <Variant name={TestVersion.variant}>
                          <SelectCharity nextPage={Navigation.charityCauses()} />
                        </Variant>
                      </Experiment>
                    </FlowRedirect>} />
                  })}

                  {[Navigation.charityCauses(), Navigation.partnerCharityCauses()].map(route => {
                    return <Route path={route} element={<FlowRedirect page={Pages.Causes}>
                      <Causes nextPage={Navigation.details()} />
                    </FlowRedirect>}>

                    </Route>
                  })}
                  {[Navigation.details(), Navigation.partnerDetails()].map(route => {
                    return <Route path={route} element={<FlowRedirect page={Pages.Details}>
                      <Details nextPage={Navigation.payment()} />
                    </FlowRedirect>}>

                    </Route>
                  })}
                  {[Navigation.payment(), Navigation.partnerPayment()].map(route => {
                    return <Route path={route} element={<FlowRedirect page={Pages.Payment}>
                      <PaymentContainer campaign="MyTenNights">
                        <Payment nextPage="/share" addressLookupStore={addressLookupStore} />
                      </PaymentContainer>
                    </FlowRedirect>}>

                    </Route>
                  })}

                  <Route path="/share" element={<ShareRedirect>
                      <Share />
                    </ShareRedirect>} />

                  <Route path="/about-us" element={<About />} />

                  <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
                  <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                  <Route path="/cookie-preferences" element={<CookiePreferences />} />

                  <Route path="/faq" element={<FAQ />} />

                  <Route path="/fees" element={<Fees />} />

                  <Route path="/contact" element={<Contact />} />

                  <Route path="/share/:shareCode" element={<ShareOptions />} />

                  <Route path="/login" element={redirect('/account/schedules')}/>

                  <Route path="/:charityID" element={<CharityLanding nextPage={Navigation.charityCauses()} />} />

                  <Route path="/link/:chainCode" element={<Home nextPage={Navigation.charitySelection()} />} />

                  <Route path="/account/schedules" element={<AuthenticatedRoute loginComponent={<Login/>} >
                    <ScheduleList />
                  </AuthenticatedRoute>} />
                  <Route path="/account/fridayGiving" element={<AuthenticatedRoute loginComponent={<Login/>} >
                    <FGScheduleList />
                  </AuthenticatedRoute>} />
                  <Route path="/account/preferences" element={<AuthenticatedRoute loginComponent={<Login/>} >
                    <MarketingPreferences />
                  </AuthenticatedRoute>} />
                  <Route path="/account/schedules/:scheduleID" element={<AuthenticatedRoute loginComponent={<Login/>} >
                    <Schedule />
                  </AuthenticatedRoute>} />
                  <Route path="/account/fridayGiving/:fridayGivingScheduleID" element={<AuthenticatedRoute loginComponent={<Login/>} >
                    <FGScheduleDetail isRunPage={false} />
                  </AuthenticatedRoute>} />
                  <Route path="/account/schedules/:scheduleID/night/:nightNumber" element={<AuthenticatedRoute loginComponent={<Login/>} >
                    <ScheduleNight />
                  </AuthenticatedRoute>} />
                  <Route path="/account/fridayGiving/:fridayGivingScheduleID/run/:paymentDate" element={<AuthenticatedRoute loginComponent={<Login/>} >
                    <FGScheduleDetail isRunPage={true} />
                  </AuthenticatedRoute>} />
                  <Route element={<NotFound />}/>

                </Routes>
                <CookieBanner />
              </Router>
            </CheckoutPlanProvider>
          </QueryClientProvider>
        </ProvideAuth>
      </ErrorBoundary>
    }
  </>
})

const AppContainer: FC = () => {
  return <App splittestingStore={splitTestingStore} />
}

export default AppContainer
